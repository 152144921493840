.header {
  position: fixed;
  z-index: 100;
  width: 100%;

  background-color: $color-default-white;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;

    width: 100%;
    height: 100%;

    background-color: $color-default-white;
  }

  &__logo {
    display: flex;
    flex-shrink: 0;
    margin-right: auto;
    margin-left: 10px;

    color: $color-affair;

    @include vp-767 {
      margin-left: 0;

      svg {
        width: 47px;
        height: 26px;
      }
    }
  }

  &__logo[href] {
    transition: color $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: transparentize($color-affair, 0.2);
      }

      &:active {
        color: transparentize($color-affair, 0.5);
      }
    }
  }

  &__phone {
    flex-shrink: 0;
    margin-left: 16px;

    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    color: $color-affair;

    transition: opacity $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 0.8;
      }

      &:active {
        opacity: 0.5;
      }
    }

    @include vp-767 {
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
    }
  }

  .container {
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;

    background-color: $color-default-white;

    @include vp-767 {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
}
