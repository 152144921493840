.car-list {
  &__item {
    display: flex;
    justify-content: space-between;

    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__car {
    font-size: 18px;
    line-height: 27px;

    @include vp-767 {
      font-size: 14px;
      line-height: 20px;
    }

    a {
      border-bottom: 1px solid $color-default-white;
      transition: color $trans-default, border-color $trans-default;

      -webkit-tap-highlight-color: transparent;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover {
          color: transparentize($color-default-white, 0.2);
          border-color: transparentize($color-default-white, 0.2);
        }

        &:active {
          color: transparentize($color-default-white, 0.5);
          border-color: transparentize($color-default-white, 0.5);
        }
      }
    }
  }

  &__price {
    margin-left: 10px;
    vertical-align: bottom;

    @include vp-767 {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__value {
    font-size: 18px;
    line-height: 27px;

    @include vp-767 {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__measure {
    font-size: 14px;
    line-height: 20px;
  }

}

