.tabs {
  margin-top: 50px;
  max-width: 100%;
}

.tabs__controls {
  display: flex;
  width: 100%;
  margin-bottom: 40px;
}

.tabs__control {
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 40px;
  opacity: 0.5;
  min-width: auto;
  width: 25%;

  &.is-active {
    opacity: 1;
    pointer-events: none;
  }
}

.tabs__content {
  margin-bottom: 40px;

  transition: height 0.3s;
}

.tabs__element {
  padding: 10px;
  width: 100%;

  &:nth-child(1) {
    background-color: rgb(250, 235, 215);
  }

  &:nth-child(2) {
    background-color: rgb(229, 250, 215);
  }

  &:nth-child(3) {
    background-color: rgb(180, 188, 233);
  }

  &:nth-child(4) {
    background-color: rgb(255, 187, 204);
  }
}

.tabs--unset .tabs__content {
  height: 100px;
}

.tabs--unset .tabs__element {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
