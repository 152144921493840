.tour {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include vp-767 {
    display: flex;
  }

  .link {
    @include vp-767 {
      font-size: 11px;
      line-height: 13px;
    }
  }

  &__heading-wrapper {
    max-width: 190px;
    width: 100%;
    margin-right: 20px;

    @include vp-374 {
      max-width: 139px;
      margin-right: 11px;

      &--max-width {
        max-width: 118px;
      }
    }

    .link {
      font-weight: 800;
    }
  }

  &__city {
    margin: 4px 0 10px 0;
    font-size: 11px;
    line-height: 13px;
  }

  &__link-wrapper {
    max-width: 285px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @include vp-767 {
      margin-top: 4px;
      justify-content: flex-end;
    }

    .link {
      width: fit-content;
      margin-bottom: 5px;
      text-decoration: underline;
      text-decoration-color: $color-transparent;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          text-decoration-color: rgba($color-default-white, 0.7);

          color: rgba($color-default-white, 0.7);
          background-color: transparent;
        }

        &:active {
          text-decoration: none;
          opacity: 0.7;
        }
      }

      @include vp-767 {
        margin-bottom: 4px;
      }
    }
  }

  &__links-social {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .tour__social-link {
      &:not(:last-of-type) {
        margin-top: 3px;
        margin-right: 19px;
      }

      @include vp-767 {
        &:first-of-type {
          margin-left: 10px;
        }
      }
    }
  }

  &__social-link {
    color: $color-default-white;

    transition: color $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: rgba($color-default-white, 0.7);
      }
    }
  }

  &__tel-links {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;

    @include vp-767 {
      justify-content: flex-end;
    }
  }

  &__icon-phone {
    margin-right: 10px;

    &--mobile {
      display: none;

      @include vp-767 {
        display: inline;
      }
    }
  }

  &__comma {
    display: inline;

    @include vp-767 {
      display: none;
    }
  }


  &__link-mail {
    margin-top: 7px;
  }


  &__transfer-phone {
    display: none;

    @include vp-767 {
      display: inline;
    }
  }

  &__icon-mail {
    margin-right: 10px;
  }

  &__icon-vk {
    @include vp-767 {
      display: block;
      margin-left: auto;
    }
  }

  &__link-phone {
    margin-right: 58px;

    &--no-indent {
      margin-right: 0;
    }


    @include vp-767 {
      margin-right: 0;


      @include vp-767 {
        margin-left: 10px;
      }
    }
  }
}
