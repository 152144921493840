.container {
  width: 100%;
  max-width: 1592px;
  margin: 0 auto;
  padding: 0 164px;

  @include vp-1440 {
    padding: 0 123px;
  }

  @include vp-1279 {
    padding: 0 80px;
  }

  @include vp-1023 {
    padding: 0 24px;
  }

  @include vp-767 {
    padding: 0 20px;
  }
}
