.relocation-block {
  .container {
    position: relative;
    padding-top: 64px;
    padding-bottom: 64px;

    &::before {
      content: "";
      position: absolute;

      top: 6px;
      left: 124px;
      width: calc(100% - 124px * 2);
      height: 1px;

      background-color: $color-gainsboro;
    }

    @include vp-1279 {
      &::before {
        left: 80px;
        width: calc(100% - 80px * 2);
      }
    }

    @include vp-1023 {
      &::before {
        left: 24px;
        width: calc(100% - 24px * 2);
      }
    }

    @include vp-767 {
      padding-top: 38px;
      padding-bottom: 42px;

      &::before {
        top: 0;
        left: 20px;
        width: calc(100% - 20px * 2);
      }
    }
  }

  &__header {
    display: grid;

    padding-top: 120px;
    padding-bottom: 120px;
    grid-template-columns: 1fr max-content;

    @include vp-1023 {
      display: flex;
      flex-direction: column;
    }

    @include vp-767 {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;

    .accent {
      display: inline-block;
      color: $color-mustard;
    }

    @include vp-767 {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .relocation-block__title-arrow {
      margin-top: 0;
      margin-right: 34px;
      margin-left: 10px;
      color: $color-mustard;

      svg {
        flex-shrink: 0;
      }

      @include vp-767 {
        margin-top: 7px;
        margin-bottom: -8px;
        margin-left: 10px;
        margin-right: 7px;

        svg {
          width: 132px;
        }
      }
    }
  }

  &__description {
    margin-top: 28px;
    margin-bottom: 0;
    max-width: 626px;

    p {
      margin-top: 0;
      margin-bottom: 18px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @include vp-1023 {
      order: 1;
    }

    @include vp-767 {
      margin-top: 32px;
      line-height: 20px;
    }
  }

  &__parameters {
    grid-column: 2 / -1;
    grid-row: 1 / 4;

    margin-top: 0;
    margin-bottom: auto;
    padding-top: 8px;
    padding-left: 0;
    list-style: none;

    @include vp-1023 {
      display: flex;
      flex-wrap: wrap;
      padding-top: 20px;

      .relocation-parameters__item {
        margin-top: 14px;
        margin-bottom: 0;

        margin-right: 40px;
      }
    }

    @include vp-767 {
      padding-top: 25px;
      .relocation-parameters__item {
        margin-top: 14px;
        margin-bottom: 0;

        margin-right: 32px;
      }
    }
  }

  &__music {
    margin-top: 24px;
    max-width: 511px;

    .paragraph {
      display: inline-block;
      margin-bottom: 20px;
    }

    @include vp-1023 {
      order: 1;
    }
  }
}

.relocation-block__header--music {
  grid-template-columns: 1fr 1fr;
  column-gap: 57px;

  @include vp-1279 {
    column-gap: 24px;
  }

  .relocation-block__parameters {
    grid-column: auto / auto;
    grid-row: 2 / 3;

    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    margin-top: 37px;
    margin-bottom: 5px;

    @include vp-1023 {
      padding-top: 0;
    }

    @include vp-767 {
      padding-top: 0;
    }

    .relocation-parameters__item {
      margin-bottom: 0;
      margin-right: 56px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .relocation-block__music {
    display: flex;
    flex-direction: column;

    align-self: center;


    grid-column: 2 / 3;
    grid-row: 1 / 4;

    margin-top: 0;
    margin-bottom: 16px;
    margin-left: auto;

    max-width: 511px;
    width: 100%;

    @include vp-1023 {
      align-self: flex-start;
      margin-top: 40px;
      margin-left: 0;
    }

    @include vp-767 {
      margin-top: 24px;

      .music-block {
        display: flex;
      }
    }

    span.paragraph {
      font-family: $ff-neuemachina;
      margin-bottom: 20px;

      @include vp-767 {
        margin-bottom: 14px;

        font-size: 20px;
        line-height: 25px;
      }
    }
  }
}
