.card-info {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  background-color: transparentize($color-default-white, 0.9);
  border-radius: 8px;

  overflow: hidden;

  width: 30%;
  height: auto;

  @include vp-767 {
    border-radius: 12px;
  }

  &--big {
    display: flex;
    flex-direction: row;

    @include vp-1023 {
      flex-direction: column;
    }

    .card-info__title {
      margin-bottom: 16px;

      @include vp-767 {
        margin-bottom: 10px;
      }
    }

    .card-info__contacts {
      margin-top: auto;

      @include vp-767 {
        margin-top: 24px;
      }
    }

    .card-info__image-wrapper {
      order: 1;
      max-width: 586px;
      overflow: hidden;

      @include vp-1439 {
        max-width: 50%;
      }

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }

      @include vp-1023 {
        order: 0;
        max-width: 100%;

        height: 320px;
      }

      @include vp-767 {
        height: 238px;
      }
    }

    .card-info__content {
      display: flex;
      flex-direction: column;
      padding: 62px 64px;

      @include vp-1023 {
        padding: 32px;
      }

      @include vp-767 {
        padding: 16px;
      }
    }

    .card-info__description {
      margin-bottom: 24px;
    }

    .card-info__button {
      margin-top: 32px;

      @include vp-767 {
        margin-top: 22px;
      }
    }
  }

  &--img {
    .card-info__contacts {
      margin-top: auto;

      @include vp-767 {
        margin-top: 16px;
      }
    }

    .card-info__note {
      margin-bottom: auto;
    }

    .card-info__button {
      margin-top: 28px;
    }
  }

  &__image-wrapper {
    position: relative;
    flex-shrink: 0;
    color: $color-default-white;

    img {
      width: 100%;
      height: 250px;

      object-fit: cover;

      transform: scale(1.01);

      @include vp-767 {
        height: 180px;
      }
    }

    svg {
      position: absolute;
      z-index: 1;
      top: 30px;
      left: 24px;

      @include vp-767 {
        top: 16px;
        left: 16px;
        width: 71px;
        height: 39px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    flex-grow: 1;
    padding: 32px;

    @include vp-767 {
      padding: 16px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 24px;

    @include vp-767 {
      margin-bottom: 11px;
    }
  }

  &__accordion {
    margin-bottom: 22px;

    @include vp-767 {
      margin-bottom: auto;
    }
  }

  &__description {
    margin-bottom: 28px;

    font-size: 14px;
    line-height: 20px;

    @include vp-767 {
      font-size: 11px;
      line-height: 16px;

      p {
        margin-bottom: 8px;
      }
    }

    p {
      margin-top: 0;
      margin-bottom: 12px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @include vp-767 {
      display: flex;
      flex-direction: column;

      margin-bottom: 24px;
    }

    button {
      display: inline-flex;
      align-items: center;
      padding: 0;
      border: none;

      user-select: none;

      font-size: 14px;
      line-height: 20px;
      color: $color-default-white;

      background-color: $color-transparent;

      -webkit-tap-highlight-color: transparent;

      cursor: pointer;

      transition: color $trans-default;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover {
          color: transparentize($color-default-white, 0.2);
        }

        &:active {
          color: transparentize($color-default-white, 0.5);
        }
      }

      @include vp-767 {
        font-size: 11px;
        line-height: 16px;
      }

      svg {
        flex-shrink: 0;
        margin-left: 8px;

        transform: rotate(0) translateZ(0);

        transition: $trans-default;

        @include vp-767 {
          width: 16px;
          height: 16px;
        }
      }

      span {
        &:last-of-type {
          display: none;
        }

        &:first-of-type {
          display: inline-block;
        }
      }
    }

    &.is-active {
      .card-info__text {
        margin-bottom: 14px;
      }

      button {
        svg {
          transform: rotate(-180deg);
        }

        span {
          &:last-of-type {
            display: inline-block;
          }

          &:first-of-type {
            display: none;
          }
        }
      }
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;

    @include vp-767 {
      font-size: 11px;
      line-height: 16px;

      p {
        margin-bottom: 8px;
      }
    }

    p {
      margin-top: 0;
      margin-bottom: 12px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__contacts {
    margin-top: auto;
  }

  &__note {
    display: block;
    margin-top: 24px;
    margin-bottom: 4px;

    font-size: 14px;
    line-height: 17px;
  }

  &__button {
    align-self: flex-start;
    margin-top: 20px;

    @include vp-767 {
      margin-top: 24px;
      width: 100%;
    }
  }
}
