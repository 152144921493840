.transport-block {
  padding-top: 54px;
  padding-bottom: 64px;

  .container {
    @include vp-1023 {
      padding-right: 0;
      padding-left: 0;
    }
  }

  @include vp-767 {
    padding-top: 36px;
    padding-bottom: 81px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    margin-bottom: 40px;

    @include vp-1023 {
      display: block;
    }

    .tabs-control {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0;
      margin-left: 0;

      .tabs-control__item {
        margin-top: 17px;
      }

      @include vp-1023 {
        flex-wrap: nowrap;
        width: 100%;
      }

      @include vp-767 {
        margin-top: 19px;
      }
    }
  }

  &__title {
    margin-top: 0;
    margin-right: 16px;
    margin-bottom: 0;

    @include vp-1023 {
      padding-right: 24px;
      padding-left: 24px;
    }

    @include vp-767 {
      margin-right: 0;
      padding-right: 20px;
      padding-left: 20px;

      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 18px;
    }
  }

  &__body {
    @include vp-1023 {
      margin-right: 24px;
      padding-right: 24px;
      padding-left: 24px;
    }

    @include vp-767 {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}
