.contacts-item-card {
  display: flex;
  align-items: center;

  margin-bottom: 16px;

  svg {
    flex-shrink: 0;

    margin-right: 16px;
    padding: 4px;

    color: $color-tangaroa;

    background-color: $color-default-white;
    border-radius: 50%;

    @include vp-767 {
      width: 20px;
      height: 20px;
    }
  }


  &__text {
    display: inline-flex;
    flex-wrap: wrap;
    margin-top: 0;
    margin-bottom: 0;

    font-size: 14px;
    line-height: 20px;

    @include vp-767 {
      font-size: 11px;
      line-height: 16px;
    }
  }

  &__link {
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;

    transition: opacity $trans-default;

    cursor: pointer;

    margin-right: 12px;

    &:last-of-type {
      margin-right: 0;
    }

    @include vp-767 {
      font-size: 11px;
      line-height: 16px;
    }

    @media (hover: hover),
    screen and (min-width: 0\0) {

      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
  }


  @include vp-767 {
    margin-bottom: 8px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
