.route-list {
  display: flex;
  column-gap: 120px;

  @include vp-767 {
    flex-direction: column;
    row-gap: 32px;
  }

  &__item {
    flex: 1 1 0;

    @include vp-767 {
      display: flex;
      flex-direction: column;
    }
  }

  &__title {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.45;
    opacity: .6;

    @include vp-767 {
      margin-bottom: 8px;
    }
  }

  &__description {
    min-height: 46px;
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 1.25;

    @include vp-767 {
      min-height: auto;
      font-size: 14px;
      line-height: 1.35;
    }
  }

  &__divider {
    display: none;
    height: 1px;
    width: 100%;
    background: $color-gainsboro;

    @include vp-767 {
      display: block;
    }
  }
}
