.roads {
  position: relative;
  overflow: hidden;

  .container {
    padding: 0;

    @include vp-1279 {
      padding: 0;
    }

    @include vp-1023 {
      padding: 0;
    }

    @include vp-767 {
      padding: 0;
    }
  }

  .title {
    margin: 0 0 42px 0;

    @include vp-767 {
      margin: 0 0 8px 0;
      font-size: 42px;
      line-height: 52px;
    }

    @include vp-419 {
      margin: 0 0 14px 0;
    }
  }

  .paragraph {
    margin: 0 0 16px 0;
    line-height: 26px;

    @include vp-419 {
      margin: 0 0 12px 0;
      line-height: 20px;
    }
  }

  .btn {
    margin-top: 16px;
    width: fit-content;
    min-width: 259px;

    @include vp-419 {
      margin-top: 4px;
      width: 100%;
      max-width: auto;
    }
  }
}

.roads__background-wrapper {
  width: 100%;
  height: 100%;

  position: absolute;
  left: 0;
  bottom: 0;

  z-index: -1;

  img {
    position: relative;
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
}

.roads__flex-layout {

  display: flex;
  align-items: center;

  position: relative;

  overflow: hidden;

  @include vp-1149 {
    flex-direction: column;
    align-items: flex-start;
  }
}

.roads__map-wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;


  flex-shrink: 0;

  @include vp-949 {
    width: 100%;
  }

  @include vp-767 {
    justify-content: center;
  }
}

.roads__map-container {
  position: relative;
  flex-shrink: 0;

  display: flex;
  align-items: center;

  width: 1367px;
  height: 750px;

  @include vp-1299 {
    width: 1150px;
  }

  @include vp-1149 {
    height: 500px;
    width: 1367px;
  }

  @include vp-949 {
    left: -6px;
    width: 1150px;
  }

  @include vp-767 {
    width: 670px;
  }

  @include vp-649 {
    width: 550px;
  }

  @include vp-559 {
    width: 400px;
  }

  @include vp-419 {
    width: 320px;
    height: 350px;
  }
}

.roads__map {
  flex-shrink: 0;
  width: 100%;

  &--mobile {
    display: none;

    @include vp-767 {
      display: block;
    }
  }

  &--desktop {
    @include vp-767 {
      display: none;
    }
  }

  svg {
    width: 100%;
  }
}

.roads__landmark {
  padding: 3px 10px;
  position: absolute;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;

    display: block;
    width: 10px;
    height: 10px;

    background-color: $color-default-white;
    border: 2px solid $color-default-white;
    border-radius: 50%;

    transition: background-color $trans-600, border-width $trans-default;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-tangaroa;
      background-color: $color-mustard;

      &::after {
        border: 2px solid $color-mustard;
        background-color: $color-tangaroa;
      }
    }
  }

  &--yugorsk {
    top: 321px;
    left: 84px;

    &::after {
      right: -5px;
      bottom: -10px;
    }
  }

  &--sovetsky {
    top: 340px;
    left: 193px;

    &::after {
      right: 117px;
      bottom: 22px;
    }
  }

  &--lake-shchuchye {

    top: 391px;
    left: 149px;

    &::after {
      right: 74px;
      bottom: 34px;
    }
  }

  &--nyagan {
    top: 226px;
    left: 229px;

    &::after {
      right: 36px;
      bottom: -16px;
    }
  }

  &--khanty {
    top: 392px;
    left: 308px;

    &::after {
      right: 35px;
      bottom: 34px;
    }
  }

  &--surgut {
    top: 320px;
    left: 567px;

    &::after {
      right: -6px;
      bottom: -8px;
    }
  }

  &--megion {
    top: 345px;
    left: 794px;

    &::after {
      right: 82px;
      bottom: -14px;
    }
  }

  &--nizhnevartovsk {
    top: 407px;
    left: 690px;

    &::after {
      right: 29px;
      bottom: 39px;
    }
  }

  &--kogalym {
    top: 218px;
    left: 716px;

    &::after {
      right: 97px;
      bottom: -4px;
    }
  }

  &--ekb {
    top: 439px;
    left: 66px;

    &::after {
      display: none;
    }
  }

  &--tumen {
    top: 682px;
    left: 390px;

    &::after {
      display: none;
    }
  }

  &--noyabrsk {
    top: 129px;
    left: 768px;

    &::after {
      right: 78px;
      bottom: 5px;

      width: 8px;
      height: 8px;

      border-color: $color-mustard;
      background-color: $color-mustard;
    }
  }

  @media (min-width: 1150px) and (max-width: 1299px) {

    &--yugorsk {
      top: 323px;
      left: 57px;

      &::after {
        right: -5px;
        bottom: -10px;
      }
    }

    &--sovetsky {
      top: 342px;
      left: 164px;

      &::after {
        right: 117px;
        bottom: 19px;
      }
    }

    &--lake-shchuchye {
      top: 387px;
      left: 78px;

      &::after {
        right: 35px;
        bottom: 34px;
      }
    }

    &--nyagan {
      top: 244px;
      left: 187px;

      &::after {
        right: 36px;
        bottom: -16px;
      }
    }

    &--khanty {
      top: 390px;
      left: 230px;

      &::after {
        right: 27px;
        bottom: 34px;
      }
    }

    &--surgut {
      top: 323px;
      left: 464px;

      &::after {
        right: -6px;
        bottom: -8px;
      }
    }

    &--megion {
      top: 335px;
      left: 648px;

      &::after {
        right: 62px;
        bottom: -21px;
      }
    }

    &--nizhnevartovsk {
      top: 407px;
      left: 561px;

      &::after {
        right: 29px;
        bottom: 39px;
      }
    }

    &--kogalym {
      top: 238px;
      left: 602px;

      &::after {
        right: 97px;
        bottom: -4px;
      }
    }

    &--ekb {
      top: 450px;
      left: 33px;
    }

    &--tumen {
      top: 643px;
      left: 316px;
    }

    &--noyabrsk {
      top: 166px;
      left: 643px;
    }
  }

  @include vp-1149 {
    &--yugorsk {
      top: 196px;
    }

    &--sovetsky {
      top: 215px;
    }

    &--lake-shchuchye {
      top: 264px;
    }

    &--nyagan {
      top: 102px;
    }

    &--khanty {
      top: 267px;
    }

    &--surgut {
      top: 195px;
    }

    &--megion {
      top: 217px;
    }

    &--nizhnevartovsk {
      top: 285px;
    }

    &--kogalym {
      top: 93px;
      left: 715px;
    }

    &--ekb {
      top: 334px;
      left: 49px;
    }

    &--tumen {
      top: 480px;
      left: 437px;
    }

    &--noyabrsk {
      top: 5px;
      left: 766px;
    }
  }

  @include vp-949 {
    &--yugorsk {
      top: 197px;
      left: 57px;
    }

    &--sovetsky {
      top: 217px;
      left: 164px;

      &::after {
        bottom: 19px;
      }
    }

    &--lake-shchuchye {
      top: 262px;
      left: 78px;

      &::after {
        right: 35px;
      }
    }

    &--nyagan {
      top: 118px;
      left: 187px;
    }

    &--khanty {
      top: 264px;
      left: 230px;

      &::after {
        right: 27px;
      }
    }

    &--surgut {
      top: 198px;
      left: 464px;

      &::after {
        bottom: -8px;
      }
    }

    &--megion {
      top: 203px;
      left: 633px;

      &::after {
        right: 47px;
        bottom: -27px;
      }
    }

    &--nizhnevartovsk {
      top: 281px;
      left: 546px;

      &::after {
        right: 13px;
        bottom: 39px;
      }
    }

    &--kogalym {
      top: 113px;
      left: 602px;
    }

    &--ekb {
      top: 330px;
      left: 33px;
    }

    &--tumen {
      top: 476px;
      left: 347px;
    }

    &--noyabrsk {
      top: 41px;
      left: 645px;
    }
  }

  @include vp-767 {
    &::after {
      width: 7px;
      height: 7px;
    }

    &--yugorsk {
      top: 171px;
      left: 73px;

      &::after {
        right: 70px;
        bottom: -44px;
      }
    }

    &--sovetsky {
      top: 223px;
      left: 105px;
    }

    &--lake-shchuchye {

      top: 289px;

      &::after {
        right: 63px;
        bottom: 39px;
      }
    }

    &--nyagan {
      top: 127px;
      left: 132px;

      &::after {
        right: 41px;
        bottom: -13px;
      }
    }

    &--khanty {
      top: 275px;

      &::after {
        right: 86px;
        bottom: 38px;
      }
    }

    &--surgut {
      top: 206px;
      left: 405px;
    }

    &--megion {
      top: 256px;
      left: 513px;

      &::after {
        right: -12px;
        bottom: 17px;
      }
    }

    &--nizhnevartovsk {
      top: 301px;
      left: 478px;

      &::after {
        right: 10px;
        bottom: 37px;
      }
    }

    &--kogalym {
      top: 127px;
      left: 424px;

      &::after {
        right: -22px;
        bottom: 3px;
      }
    }

    &--ekb {
      top: 390px;
      left: 78px;
    }

    &--tumen {
      top: 465px;
      left: 295px;
    }

    &--noyabrsk {
      top: 26px;
      left: 528px;

      &::after {
        right: 22px;
        bottom: -16px;
      }
    }
  }

  @include vp-649 {
    &--yugorsk {
      top: 177px;
      left: 36px;

      &::after {
        right: 71px;
        bottom: -38px;
      }
    }

    &--sovetsky {
      top: 225px;
      left: 66px;
    }

    &--lake-shchuchye {
      top: 286px;
      left: 38px;
    }

    &--nyagan {
      top: 134px;
      left: 87px;
    }

    &--khanty {
      top: 270px;
      left: 172px;

      &::after {
        bottom: 35px;
      }
    }

    &--surgut {
      top: 206px;
      left: 331px;
    }

    &--megion {
      top: 254px;
      left: 428px;
    }

    &--nizhnevartovsk {
      top: 298px;
      left: 394px;
    }

    &--kogalym {
      top: 135px;
      left: 346px;
    }

    &--ekb {
      top: 365px;
      left: 37px;
    }

    &--tumen {
      top: 449px;
      left: 232px;
    }

    &--noyabrsk {
      top: 43px;
      left: 444px;

      &::after {
        right: 22px;
        bottom: -16px;
      }
    }
  }

  @include vp-559 {
    font-size: 11px;
    line-height: 16px;

    &::after {
      width: 5px;
      height: 5px;
      border: 1px solid $color-default-white;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {


        &::after {
          border: 1px solid $color-mustard;
        }
      }
    }

    &--yugorsk {
      top: 196px;
      left: 28px;

      &::after {
        right: 51px;
        bottom: -30px;
      }
    }

    &--sovetsky {
      top: 234px;
      left: 49px;

      &::after {
        right: 81px;
        bottom: 15px;
      }
    }

    &--lake-shchuchye {
      top: 277px;
      left: 21px;

      &::after {
        right: 35px;
        bottom: 28px;
      }
    }

    &--nyagan {
      top: 169px;
      left: 63px;

      &::after {
        right: 28px;
        bottom: -8px;
      }
    }

    &--khanty {
      top: 266px;
      left: 131px;

      &::after {
        right: 57px;
        bottom: 25px;
      }
    }

    &--surgut {
      top: 217px;
      left: 241px;
    }

    &--megion {
      top: 258px;
      left: 311px;
    }

    &--nizhnevartovsk {
      top: 282px;
      left: 299px;

      &::after {
        bottom: 24px;
      }
    }

    &--kogalym {
      top: 169px;
      left: 249px;
    }

    &--ekb {
      top: 336px;
      left: 17px;
    }

    &--tumen {
      top: 400px;
      left: 164px;
    }

    &--noyabrsk {
      top: 92px;
      left: 315px;
    }
  }

  @include vp-419 {

    &--yugorsk {
      top: 130px;
      left: 18px;

      &::after {
        right: 48px;
        bottom: -22px;
      }
    }

    &--sovetsky {
      top: 166px;
      left: 33px;

      &::after {
        right: 73px;
        bottom: 19px;
      }
    }

    &--lake-shchuchye {
      top: 197px;

      &::after {
        right: 44px;
        bottom: 25px;
      }
    }

    &--nyagan {
      top: 109px;
      left: 43px;

      &::after {
        right: 26px;
        bottom: -3px;
      }
    }

    &--khanty {
      top: 187px;
      left: 91px;

      &::after {
        right: 53px;
        bottom: 23px;
      }
    }

    &--surgut {
      top: 148px;
      left: 187px;

      &::after {
        right: 1px;
        bottom: -3px;
      }
    }

    &--megion {
      top: 178px;
      left: 245px;

      &::after {
        right: -2px;
        bottom: 13px;
      }
    }

    &--nizhnevartovsk {
      top: 203px;
      left: 220px;

      &::after {
        right: 9px;
        bottom: 24px;
      }
    }

    &--kogalym {
      top: 109px;
      left: 197px;

      &::after {
        right: -7px;
        bottom: 6px;
      }
    }

    &--ekb,
    &--tumen,
    &--noyabrsk {
      font-size: 8px;
      line-height: 12px;
    }

    &--ekb {
      top: 252px;
    }

    &--tumen {
      top: 287px;
      left: 134px;
    }

    &--noyabrsk {
      top: 55px;
      left: 254px;

      &::after {
        right: 16px;
        bottom: -5px;
      }
    }
  }
}

.roads__nav-mark {
  padding: 3px;
  position: absolute;

  width: 23px;
  height: 23px;

  background-color: $color-mustard;
  border-radius: 50%;

  z-index: 1;

  svg {
    color: $color-tangaroa;
    width: 15px;
    height: 17px;
  }

  &--ekb {
    top: 293px;
    left: 226px;
  }

  &--tumen {
    top: 539px;
    left: 435px;
  }

  &--noyabrsk {
    padding: 2px 2px 3px 5px;
    top: 177px;
    left: 690px;

    svg {
      transform: rotate(180deg);
    }
  }

  @media (min-width: 1150px) and (max-width: 1299px) {
    &--ekb {
      top: 306px;
      left: 189px;
    }

    &--tumen {
      top: 512px;
      left: 364px;
    }

    &--noyabrsk {
      top: 207px;
      left: 578px;
    }
  }

  @include vp-1149 {
    &--ekb {
      top: 170px;
    }

    &--tumen {
      top: 415px;
    }

    &--noyabrsk {
      top: 53px;
    }
  }

  @include vp-949 {
    &--ekb {
      top: 181px;
      left: 189px;
    }

    &--tumen {
      top: 387px;
      left: 364px;
    }

    &--noyabrsk {
      top: 81px;
      left: 578px;
    }
  }

  @include vp-767 {
    &--ekb {
      top: 203px;
      left: 117px;
    }

    &--tumen {
      top: 393px;
      left: 307px;
    }

    &--noyabrsk {
      top: 88px;
      left: 520px;
    }
  }

  @include vp-649 {
    padding: 4px 2px 2px 2px;
    width: 15px;
    height: 15px;

    svg {
      color: $color-tangaroa;
      width: 10px;
      height: 10px;
    }

    &--ekb {
      top: 211px;
      left: 82px;
    }

    &--tumen {
      top: 383px;
      left: 252px;
    }

    &--noyabrsk {
      padding: 2px 2px 3px 3px;
      top: 107px;
      left: 447px;
    }
  }

  @include vp-559 {

    &--ekb {
      top: 220px;
      left: 57px;
    }

    &--tumen {
      top: 345px;
      left: 182px;
    }

    &--noyabrsk {
      top: 143px;
      left: 323px;
    }
  }

  @include vp-419 {

    padding: 2px 2px 1px 1px;
    width: 10px;
    height: 10px;

    &--ekb {
      top: 151px;
      left: 46px;
    }

    &--tumen {
      top: 252px;
      left: 146px;
    }

    &--noyabrsk {
      padding: 1px 1px 2px 2px;
      top: 91px;
      left: 259px;
    }
  }
}

.roads__info-wrapper {
  margin-left: -400px;
  padding: 40px 164px 50px 0;


  display: flex;
  flex-direction: column;
  justify-content: center;

  z-index: 1;

  @include vp-1439 {
    margin-left: -458px;
  }

  @include vp-1299 {
    margin-left: -390px;
    padding: 40px 123px 50px 0;
  }

  @include vp-1149 {
    margin-top: -46px;
    margin-left: 0;
    padding: 0 123px 50px 123px;
  }

  @include vp-1023 {
    padding: 0 24px 50px 24px;
  }

  @include vp-767 {
    padding: 0 20px 40px 20px;
  }
}


.roads__text {
  &--mobile {
    display: none;
  }

  @include vp-767 {
    &--desktop {
      display: none;
    }

    &--mobile {
      display: inline;
    }
  }
}
