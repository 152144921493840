.route-block {
  .container {
    position: relative;
    padding-top: 49px;
    padding-bottom: 64px;

    @include vp-767 {
      padding-top: 80px;
      padding-bottom: 80px;

      &::before {
        content: "";
        position: absolute;

        top: 0;
        left: 20px;
        width: calc(100% - 20px * 2);
        height: 1px;

        background-color: $color-gainsboro;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 80px;

    @include vp-1023 {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 32px;
      margin-bottom: 64px;
    }
  }

  &__title {
    width: 45%;
    margin: 0;
    font-size: 30px;
    font-family: $ff-neuemachina;
    line-height: 1.25;
    font-weight: 300;

    @include vp-1023 {
      width: auto;
    }

    @include vp-767 {
      font-size: 20px;
    }
  }

  &__info {
    display: flex;
    column-gap: 32px;
    width: 38%;

    @include vp-1023 {
      width: auto;
    }

    svg {
      flex: 0 0 auto;
    }

    p {
      margin: 0;
      font-size: 14px;
      line-height: 145%;
    }

    a {
      text-decoration: underline;
    }
  }

  &__body {
    .tabs-control {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin: 0 0 24px;

      .tabs-control__item {
        margin: 0;
      }

      @include vp-1023 {
        flex-wrap: nowrap;
        width: 100%;
        margin-bottom: 32px;
      }

      @include vp-767 {
        margin-top: 19px;
        padding-left: 0;
      }
    }
  }

  &__item {
    width: 100%;
    min-height: 357px;
    padding: 64px;
    border-radius: 8px;
    background-color: rgba(255,255,255,.1);

    @include vp-767 {
      min-height: auto;
      padding: 0;
      border-radius: 0;
      background: none;
    }
  }
}
