.nature {
  min-height: 1224px;
  padding-top: 59px;
  padding-bottom: 65px;

  @include vp-1023 {
    padding-top: 29px;
    padding-bottom: 79px;
  }

  .title {
    max-width: 616px;
    margin: 0 0 17px 0;

    @include vp-1023 {
      max-width: 280px;
      margin: 0 0 27px 0;
    }
  }

  .paragraph {
    margin: 0;

    &:not(:last-of-type) {
      margin-bottom: 18px;
    }

    &:last-of-type {
      margin-bottom: 30px;
    }

    @include vp-1023 {
      line-height: 20px;

      &:not(:last-of-type) {
        margin-bottom: 12px;
      }

      &:last-of-type {
        margin-bottom: 32px;
      }
    }
  }

  .btn {
    @include vp-1023 {
      width: 100%;
    }
  }

  .pagination {
    display: none;
    justify-content: center;

    @include vp-1023 {
      display: flex;
    }
  }

  &__wrapper {
    position: relative;

    min-height: 953px;
  }

  &__top-wrapper {
    @include vp-1023 {
      position: relative;
      max-width: 610px;
    }
  }

  &__bridge-img {
    position: absolute;
    left: 0;

    margin-top: 49px;

    @include vp-1279 {
      img {
        max-width: 85%;
      }
    }

    @include vp-1023 {
      position: relative;

      margin: 0 0 31px 0;

      img {
        max-width: 88%;
      }
    }
  }

  &__river-img {
    position: absolute;
    left: 37%;

    @include vp-1439 {
      & img {
        max-width: 100%;
      }
    }

    @include vp-1279 {
      display: none;
    }

    @include vp-1023 {
      top: -48px;
      left: unset;
      right: 0;

      display: block;
      width: 47%;
    }
  }

  &__content-wrapper {
    position: relative;
    right: -64%;

    max-width: 428px;

    @include vp-1279 {
      right: -52%;
    }

    @include vp-1023 {
      position: relative;
      right: 0;
      max-width: unset;
    }
  }

  &__text-wrapper {
    margin-bottom: 79px;

    @include vp-1023 {
      max-width: 80%;
    }

    @include vp-767 {
      max-width: 100%;
    }
  }

  &__sunset-img {
    position: relative;
    left: -76%;
    z-index: 1;

    min-width: 510px;

    @include vp-1279 {
      left: -29%;
    }

    @include vp-1023 {
      display: none;
    }
  }

  &__img-text {
    .title {
      position: absolute;
      left: 30px;
      bottom: 40px;

      margin: 0;

      @include vp-1023 {
        left: 24px;
        bottom: 34px;

        margin: 0;

        font-size: 42px;
      }
    }
  }

  &__forest-img {
    position: absolute;
    right: 0;
    bottom: 75px;

    @include vp-1279 {
      display: none;
    }
  }

  &__swamp-img {
    position: absolute;
    bottom: 0;

    @include vp-1279 {
      bottom: 69px;
    }

    @include vp-1023 {
      display: none;
    }
  }

  &__slider {
    display: none;

    @include vp-1023 {
      display: block;
      margin: 0 0 19px 0;
    }

    img {
      max-height: 640px;
      width: 100%;
      object-fit: cover;

      @include vp-767 {
        max-height: 440px;
      }
    }
  }
}
