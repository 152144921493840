.btn {
  display: inline-flex;
  justify-content: center;
  margin: 0;
  padding: 12px 24px;

  color: $color-tangaroa;
  text-align: center;

  background-color: $color-mustard;

  border: none;
  border-radius: 33px;
  cursor: pointer;
  user-select: none;

  font-family: $ff-inter;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;

  transition: color $trans-default, background-color $trans-default;

  border-radius: 33px;

  -webkit-tap-highlight-color: transparent;

  &--round {
    padding: 12px;
    border-radius: 50%;
  }

  &--secondary {
    padding: 9px 24px;

    min-width: 160px;

    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: $color-default-white;
    background-color: $color-arsenic;
    border: 1px solid $color-transparent;

    transition: color $trans-default, border-color $trans-default, opacity $trans-default;

    white-space: nowrap;

    cursor: pointer;

    -webkit-tap-highlight-color: transparent;

    @include vp-767 {
      padding: 7px 20px;
      min-width: 134px;

      font-size: 15px;
      line-height: 23px;
    }

    @media (hover: hover),
    screen and (min-width: 0\0) {
      &:hover {
        background-color: $color-arsenic;
        border-color: $color-mustard;
      }

      &:active {
        opacity: 0.5;
      }

      &:focus-visible {
        outline: none;
        border-color: $color-mustard;
      }
    }

    &.is-active {
      color: $color-tangaroa;
      background-color: $color-mustard;


      @media (hover: hover),
      screen and (min-width: 0\0) {
        &:hover {
          background-color: $color-mustard;
          border-color: $color-mustard;
          color: $color-tangaroa;
        }

        &:active {
          opacity: 0.5;
        }

        &:focus-visible {
          outline: none;
          border-color: $color-mustard;
        }
      }
    }

    @include vp-767 {
      font-size: 15px;
      line-height: 23px;
    }
  }

  &:disabled {
    opacity: 0.5;

    pointer-events: none;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover {
      color: $color-default-white;
      background-color: transparentize($color-default-white, 0.78);
    }

    &:focus-visible {
      color: $color-default-white;
      background-color: transparentize($color-default-white, 0.78);
    }

    &:active {
      background-color: transparent;
    }
  }

  &--focus {
    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        color: $color-default-white;
        background-color: transparentize($color-default-white, 0.78);
      }

      &:focus {
        color: $color-default-white;
        background-color: transparentize($color-default-white, 0.78);
      }

      &:active {
        background-color: transparent;
      }
    }
  }
}
