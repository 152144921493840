.main-screen {
  position: relative;

  min-height: calc(100vh);
  min-height: calc(100 * var(--vh, 1vh));
  padding: 190px 0 79px 0;

  background-color: $color-tangaroa;

  @include vp-767 {
    min-height: calc(100vh);
    min-height: calc(100 * var(--vh, 1vh));

    padding: 153px 0 52px 0;
  }

  .title {
    margin: 0 0 45px 0;

    @include vp-767 {
      margin: 0 0 29px 0;
    }
  }

  .paragraph {
    max-width: 611px;
    margin: 0;

    line-height: 28px;

    @include vp-767 {
      margin: 0 0 43px 0;
      line-height: 22px;
    }
  }

  .pagination {
    top: -46px;
    left: -7px;

    @include vp-767 {
      top: -36px;
      left: -4px
    }
  }

  &--inner {
    padding: 201px 0 141px 0;

    .title {
      margin: 0 0 88px 0;
    }

    @include vp-767 {
      padding: 168px 0 84px 0;

      .title {
        margin: 0 0 45px 0;
      }
    }
  }

  &__swiper {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;
  }

  &__background {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 1;
  }

  &__arrow-icon {
    display: block;
    width: 73px;
    height: 103px;
    margin-bottom: 23px;

    fill: none;

    @include vp-767 {
      display: none;
    }

    svg .arrow {
      transform: translate3d(0, 0, 0);
      transition: transform $trans-default;
    }

    &:hover svg .arrow ,
    &:focus svg .arrow {
      transform: translate3d(0, 10%, 0);
    }
  }

  &__bottom-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @include vp-767 {
      display: block;
    }
  }

  &__text {
    width: 51%;

    .paragraph {
      &:not(:last-child) {
        margin: 0 0 20px 0;
      }
    }

    @include vp-949 {
      width: 100%;
      margin-bottom: 20px;
    }

    @include vp-767 {
      margin-bottom: 0;
    }
  }

  &__yandex-music {
    align-self: flex-start;
    max-width: 511px;
    width: 44%;

    @include vp-1023 {
      margin-right: 41px;
    }

    @include vp-949 {
      max-width: unset;
      width: 100%;
    }

    @include vp-767 {
      width: 100%;
      max-width: unset;
      max-height: 71px;
      margin-right: 0;
    }
  }
}
