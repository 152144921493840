.footer {
  padding: 53px 0;

  background-color: $color-blackcurrant;

  @include vp-767 {
    padding: 43px 0;
    text-align: center;
  }

  .container {
    max-width: 1476px;
    padding: 0 97px;

    @include vp-1023 {
      padding: 0 24px;
    }

    @include vp-767 {
      padding: 0 20px;
    }
  }

  .title {
    max-width: 230px;
    margin: 0;

    @include vp-767 {
      margin: 0 auto;
    }
  }

  .link {
    display: inline;

    font-size: 17px;
    color: $color-dark-gray;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: transparent;
        color: $color-default-white;
      }
    }

    &--svg {
      color: $color-default-white;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-dark-gray;
        }
      }
    }

    &--logo {
      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }

    &--inline-flex {
      display: inline-flex;
    }

    @include vp-767 {
      font-size: 13px;
      line-height: 14px;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @include vp-767 {
      grid-template-columns: 1fr;
      justify-items: center;
    }
  }

  &__nav {
    margin-bottom: 28px;
    padding-left: 15px;

    &:last-of-type {
      grid-column: -1/-2;
    }

    @include vp-767 {
      padding-left: 0;
    }
  }

  &__nav-list {
    max-width: 283px;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    &:not(:last-of-type) {
      margin-bottom: 4px;
    }

    font-size: 14px;
    color: $color-dark-gray;

    @include vp-767 {
      &:not(:last-of-type) {
        margin-bottom: -4px;
      }
    }
  }

  .developer {
    margin: 40px 0 0;
    font-size: 14px;
    line-height: 25px;

    @include vp-767 {
      margin-top: 28px;
    }
  }
}
