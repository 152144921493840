.tabs-control {
  list-style: none;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;

  @include vp-1023 {
    display: flex;
    flex-wrap: nowrap;

    padding-right: 24px;
    padding-left: 24px;

    overflow-x: auto;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include vp-767 {
    padding-right: 20px;
    padding-left: 20px;
  }

  &__item {
    margin-right: 24px;

    @include vp-767 {
      margin-right: 12px;
    }

    &:last-child {
      margin-right: 0;

      @include vp-1023 {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: -24px;

          width: 24px;
          height: 24px;
        }
      }

      @include vp-767 {
        &::after {
          right: -20px;

          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

