.header-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  margin-top: 0;
  margin-bottom: 0;

  padding: 0;

  list-style: none;

  @include vp-767 {
    display: none;
  }

  &__item {
    padding-right: 16px;
    padding-left: 16px;

    font-size: 16px;
    color: $color-affair;

    transition: opacity $trans-default;

    @include vp-1023 {
      padding-right: 12px;
      padding-left: 12px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 0.8;
      }

      &:active {
        opacity: 0.5;
      }
    }
  }
}
