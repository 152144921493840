.video-slide{
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.video-slide__info {
  padding: 5px;
  position: absolute;
  top: 35px;
  left: 35px;

  background-color: $color-black-transparent-02;

  z-index: 1;

  @include vp-767 {
    top: 12px;
    left: 11px;
  }

  .title {
    margin: 0 0 10px 0;
    letter-spacing: 0.01em;

    @include vp-767 {
      margin: 0;
    }
  }

  .paragraph {
    margin: 0;

    @include vp-767 {
      font-size: 11px;
      height: 16px;
    }
  }
}

.video-slide__placeholder {
  height: auto;
  width: 100%;

  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include vp-767 {
      height: 440px;
    }
  }

}

.video-slide__video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;

  overflow: hidden;

  background-color: transparent;
  transition: background-color $trans-600;

  &.is-active {
    background-color: $color-default-black;
  }

  video {
    height: 100%;
    max-width: none;
  }
}

.video-slide__play-button {
  margin: 0;
  padding: 0;
  width: 96px;
  height: 96px;

  align-items: center;

  position: absolute;
  top: calc(50% - 48px);
  left: calc(50% - 48px);

  opacity: 1;
  transition: transform $trans-default, opacity $trans-600, color $trans-default, background-color $trans-default;;

  z-index: 1;

  @include vp-1023 {
    width: 75px;
    height: 75px;

    top: calc(50% - 34px);
    left: calc(50% - 37px);
  }

  @include vp-767 {
    width: 70px;
    height: 70px;

    top: calc(50% - 35px);
    left: calc(50% - 35px);
  }

  svg {
    margin-left: 5px;
    flex-shrink: 0;
    width: 37px;
    height: 32px;

    @include vp-1023 {
      width: 29px;
      height: 25px;
    }

    @include vp-767 {
      margin-left: 0;
      width: 27px;
      height: 23px;
    }
  }

  &.is-hidden {
    opacity: 0;
    transform: scale(1.5);
    pointer-events: none;
  }

}

.video-slide__bottom-buttons {
  position: absolute;
  bottom: 29px;
  left: calc(50% - 102px);

  display: flex;

  z-index: 1;

  @include vp-767 {
    bottom: 27px;
    left: calc(50% - 81px);
  }

  .video-slide__bottom-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 47px;
    height: 47px;

    background-color: $color-light-sea-green;
    border-radius: 50%;

    @include vp-767 {
      width: 42px;
      height: 42px;
    }

    &:not(:last-child) {
      margin-right: 32px;

      @include vp-767 {
        margin-right: 18px;
      }
    }

    svg {
      width: 27px;
      height: 27px;

      flex-shrink: 0;

      color: $color-default-white;

      @include vp-767 {
        width: 23px;
        height: 23px;
      }
    }
  }


}
