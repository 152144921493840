.people {
  padding: 120px 0 47px;

  @include vp-767 {
    padding: 48px 0 40px;
    padding-bottom: 30px;
  }
}

.people__info {
  margin-bottom: 63px;

  display: flex;
  justify-content: space-between;

  @include vp-767 {
    margin-bottom: 34px;
    flex-direction: column;
  }

  .title {
    margin: 0;

    @include vp-767 {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .paragraph {
    margin: 0;
    padding-right: 10px;
    max-width: 512px;


    @include vp-767 {
      margin-top: 10px;
      max-width: none;

      line-height: 20px;

      br {
        display: none;
      }

    }
  }
}

.people__video-slider {
  padding-bottom: 50px;
}

.people__slider-button {
  position: absolute;
  top: calc(50% - 55px);

  background-color: transparent;
  color: $color-default-white;
  opacity: 1;

  transition: opacity $trans-default;

  z-index: 1;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: transparent;
      opacity: 0.5;
    }

    &:active {
      background-color: transparent;
    }
  }

  @include vp-767 {
    display: none;
  }

  &--next {
    right: 10px;
  }

  &--prev {
    transform: rotate(180deg);
    left: 10px;
  }

  svg {
    color: inherit;
    width: 18px;
    height: 38px;
  }

}
