.description-list {
  list-style: none;
  margin: 0;
  padding: 0;

  font-weight: 400;
  font-size: 24px;

  @include vp-767 {
    font-size: 13px;
    line-height: 20px;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      margin: 0 0 17px 0;
    }

    @include vp-767 {
      &:not(:last-of-type) {
        margin: 0 0 20px 0;
      }
    }
  }

  &__icon {
    margin: 0 17px 0 0;

    svg {
      width: 40px;
      height: 40px;
      fill: none;

      @include vp-767 {
        width: 24px;
        height: 24px;
      }
    }
  }
}
