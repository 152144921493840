.link {
  display: flex;
  align-items: center;

  font-family: $ff-inter;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-decoration: none;

  border-radius: 27px;
  color: $color-default-white;

  cursor: pointer;

  transition: color $trans-default, background-color $trans-default, text-decoration-color $trans-default;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-tangaroa;
      background-color: $color-mustard;
    }

    &:active {
      background-color: transparent;
    }
  }

  &--underline {
    text-decoration: underline;
    text-decoration-color: $color-default-white;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        text-decoration-color: $color-transparent;
        color: $color-default-white;
        background-color: transparent;
      }

      &:active {
        opacity: 0.7;
      }
    }
  }

  &--fs-14 {
    font-size: 14px;
    line-height: 17px;
  }

  &--nowrap {
    white-space: nowrap;
  }

  &--fs-13 {
    font-size: 13px;
    line-height: 19px
  }

  &--yellow {
    color: $color-mustard;
  }

  &--inactive {
    pointer-events: none;
  }
}
