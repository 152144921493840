.card-transport {
  display: flex;
  background-color: transparentize($color-default-white, 0.9);

  border-radius: 8px;

  overflow: hidden;

  @include vp-767 {
    background-color: $color-transparent;
    border-radius: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;

    padding: 64px;

    @include vp-1279 {
      padding: 48px;
    }

    @include vp-767 {
      padding: 0;
    }

    &.is-active {
      .card-transport__accordion-button {
        color: $color-mustard;
        border-color: $color-mustard;
      }
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;

    font-family: $ff-neuemachina;
    font-size: 30px;
    line-height: 38px;

    @include vp-767 {
      font-size: 20px;
      line-height: 25px;
    }
  }

  &__description {
    margin-top: 18px;

    font-size: 18px;
    line-height: 26px;

    @include vp-767 {
      margin-top: 20px;
      font-size: 14px;
      line-height: 20px;
    }

    p {
      margin-top: 0;
      margin-bottom: 16px;

      @include vp-767 {
        margin-bottom: 12px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__accordion-button {
    padding: 0;

    color: $color-default-white;
    background-color: $color-transparent;
    border: none;

    border-bottom: 1px solid $color-default-white;

    cursor: pointer;

    transition: color $trans-default, border-color $trans-default;

    -webkit-tap-highlight-color: transparent;

    user-select: none;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        color: transparentize($color-default-white, 0.2);
        border-color: transparentize($color-default-white, 0.2);
      }

      &:active {
        color: transparentize($color-default-white, 0.5);
        border-color: transparentize($color-default-white, 0.5);
      }
    }
  }

  &__image-wrapper {
    flex-shrink: 0;
    max-width: 50%;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    @include vp-1023 {
      display: none;
    }
  }

  &__car-list {
    .car-list {
      margin: 0;
      padding-top: 40px;

      @include vp-767 {
        padding-top: 21px;
      }
    }
  }
}
