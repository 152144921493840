.location-nav {
  padding-top: 46px;
  padding-bottom: 62px;

  @include vp-767 {
    position: relative;

    padding-top: 78px;
    padding-bottom: 46px;

    &::before {
      content: "";
      position: absolute;

      top: 0;
      left: 20px;
      width: calc(100% - 20px * 2);
      height: 1px;

      background-color: $color-gainsboro;
    }
  }

  &__header {
    margin-bottom: 40px;

    @include vp-767 {
      margin-bottom: 32px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 22px;

    font-family: $ff-neuemachina;
    font-weight: 300;
    font-size: 30px;
    line-height: 38px;

    @include vp-767 {
      font-size: 20px;
      line-height: 25px;
      max-width: 100%;
    }
  }

  &__description {
    margin-bottom: 18px;

    font-size: 18px;
    line-height: 26px;

    @include vp-767 {
      font-size: 14px;
      line-height: 20px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    margin: 0 auto;
    padding: 0;
    list-style: none;

    width: 100%;
    max-width: 1592px;
    padding: 0 164px;

    @include vp-1440 {
      max-width: 1440px;
      padding: 0 123px;
    }

    @include vp-1279 {
      padding-right: 80px;
      padding-left: 80px;
    }

    @include vp-1023 {
      padding-right: 24px;
      padding-left: 24px;
      flex-wrap: nowrap;

      overflow-x: auto;

      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @include vp-767 {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  &__item {
    margin-right: 32px;
    margin-bottom: 16px;

    @include vp-767 {
      margin-right: 12px;
    }

    &:last-child {
      margin-right: 0;

      @include vp-1023 {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: -24px;

          width: 24px;
          height: 24px;
        }
      }

      @include vp-767 {
        &::after {
          right: -20px;

          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &__link {
    padding: 7px 15px;
    min-width: auto;

    @include vp-1023 {
      padding: 7px 15px;
      min-width: auto;
    }

    @include vp-767 {
      padding: 7px 15px;
      min-width: auto;
    }
  }
}
