.location-map {
  padding-bottom: 128px;

  @include vp-767 {
    padding-bottom: 80px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 8px;
    font-family: $ff-neuemachina;
    font-weight: 300;
    font-size: 30px;
    line-height: 38px;

    @include vp-767 {
      margin-bottom: 12px;
      font-size: 20px;
      line-height: 25px;
    }
  }

  &__description {
    margin: 0 0 40px;
    font-size: 18px;
    line-height: 1.45;

    @include vp-767 {
      margin-bottom: 32px;
      font-size: 14px;
    }
  }

  &__wrapper {
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    user-select: none;

    @include vp-767 {
      border-radius: 12px;
    }

    iframe {
      width: 100%;
      height: 582px;
      border: none;

      @include vp-767 {
        height: 158px;
      }
    }
  }
}
