.location-block {
  .container {
    position: relative;
    padding-top: 53px;
    padding-bottom: 53px;

    &::before {
      content: "";
      position: absolute;

      top: 0;
      left: 124px;
      width: calc(100% - 124px * 2);
      height: 1px;

      background-color: $color-gainsboro;
    }

    @include vp-1279 {
      &::before {
        left: 80px;
        width: calc(100% - 80px * 2);
      }
    }

    @include vp-1023 {
      &::before {
        left: 24px;
        width: calc(100% - 24px * 2);
      }
    }

    @include vp-767 {
      padding-top: 38px;
      padding-bottom: 38px;

      &::before {
        left: 20px;
        width: calc(100% - 20px * 2);
      }
    }
  }

  &__header {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    column-gap: 30px;

    padding-top: 64px;
    padding-bottom: 64px;

    @include vp-1023 {
      display: flex;
      flex-direction: column;
      gap: 0;
    }

    @include vp-767 {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &__header--no-desc {
    padding-top: 32px;
    padding-bottom: 42px;
  }

  &__title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    margin-top: 0;
    margin-bottom: 0;

    +.location-block__description {
      margin-top: 26px;

      p {
        margin-top: 0;
        margin-bottom: 18px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      @include vp-767 {
        margin-top: 19px;
        max-width: 100%;
        line-height: 20px;
        letter-spacing: -0.02em;
      }
    }
  }

  &__description {
    grid-column: 1 / 2;
    margin-bottom: 0;

    max-width: 87%;
  }

  &__music {
    display: flex;
    flex-direction: column;


    grid-column: 2 / 3;
    grid-row: 1 / span 2;

    margin-top: 24px;
    margin-left: auto;

    max-width: 511px;
    width: 100%;

    @include vp-1023 {
      align-self: flex-start;
      margin-top: 40px;
      margin-left: 0;
    }

    @include vp-767 {
      margin-top: 24px;

      .music-block {
        display: flex;
      }
    }

    span.paragraph {
      font-family: $ff-neuemachina;
      margin-bottom: 20px;

      @include vp-767 {
        margin-bottom: 14px;

        font-size: 20px;
        line-height: 25px;
      }
    }
  }

  &__item {
    display: flex;
    flex-wrap: wrap;

    padding-top: 64px;
    padding-bottom: 64px;

    display: flex;
    flex-wrap: wrap;
    gap: 0;
    width: calc(100% + 32px);
    margin-bottom: 0;

    @include vp-1023 {
      width: calc(100% + 24px);
      margin-bottom: -24px;
    }

    @include vp-767 {
      width: 100%;
      margin-bottom: 0;
    }

    .card-info {
      margin-right: 32px;
      margin-bottom: 32px;
      width: calc(30% - 32px);

      @include vp-1023 {
        width: calc(30% - 24px);
        margin-right: 24px;
        margin-bottom: 24px;
      }

      @include vp-767 {
        width: 100%;
        margin-right: 0;
        margin-bottom: 0;
      }
    }

    .card-info--big {
      width: 100%;

      margin-bottom: 32px;

      @include vp-767 {
        margin-bottom: 20px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    > .title {
      margin-top: 0;
      margin-bottom: 32px;
      width: 100%;

      @include vp-767 {
        margin-bottom: 24px;

        font-size: 20px;
        line-height: 25px;
      }
    }

    @include vp-767 {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &__slider {
    position: relative;

    @include vp-767 {
      padding-bottom: 80px;
    }

    .swiper-initialized {
      width: calc(100% - 32px);
      margin-left: 0;

      @include vp-1023 {
        width: calc(100% - 24px);
      }

      @include vp-767 {
        width: 100%;
      }
    }

    .slider-pagination {
      bottom: 34px;
      left: -2px;
    }
  }
}


