.main-nav {
  position: fixed;
  z-index: 4;
  top: 30px;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;

  transform: translateX(calc(100% - 57px));
  transition: transform $trans-default;

  &.is-active {
    transform: translateX(0%);

    +.main-nav-overlay {
      opacity: 1;

      pointer-events: auto;
    }
  }

  @include vp-767 {
    position: static;
    // bottom: auto;
    align-items: flex-start;
    transform: translateX(0);

    &.is-active {
      position: fixed;
      top: 32px;
      right: 0;
      left: 0;

      .main-nav__wrapper {
        position: relative;
        transform: translateX(0);
        height: 100%;
      }

      .main-nav-overlay {
        pointer-events: auto;
        background-color: red;
      }
    }
  }


&__wrapper {
  display: flex;
  align-items: center;

  padding-right: 67px;
  padding-left: 61px;

  min-width: 422px;

  height: 100%;

  background-color: $color-tangaroa;

  overflow-y: auto;

  @include vp-767 {
    position: fixed;
    top: 0;
    right: 0;

    width: 100%;

    align-items: flex-start;

    padding: 0;

    width: 100%;
    min-width: auto;
    height: auto;
    // height: 100%;

    background-color: $color-transparent;

    transform: translateX(100%);

    transition: transform $trans-default 0.2s;
  }
}

&__list {
  margin-top: auto;
  margin-bottom: auto;

  padding-top: 45px;
  padding-bottom: 30px;
  padding-left: 0;

  list-style: none;

  @include vp-767 {
    margin-top: 0;

    padding-top: 55px;
    padding-right: 20px;
    padding-bottom: 57px;
    padding-left: 20px;

    width: 100%;
    min-height: 259px;

    background-color: $color-tangaroa;

    overflow-y: auto;

  }
}

&__item {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  @include vp-767 {
    margin-bottom: 16px;

  }
}

&__link {
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 18px;
  line-height: 22px;
  color: $color-default-white;

  transition: color $trans-default, opacity $trans-default;

  -webkit-tap-highlight-color: transparent;

  svg {
    flex-shrink: 0;
    margin-left: 16px;

    opacity: 0;
    transform: translateX(-40%);

    transition: opacity $trans-default, transform $trans-default;
  }

  @include vp-767 {
    display: inline-flex;
    font-size: 16px;
    line-height: 20px;

    svg {
      display: none;
    }
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-mustard;

      svg {
        opacity: 1;
        transform: translateX(0);
      }
    }

    &:active {
      opacity: 0.5;
    }
  }
}

&__toggle {
  position: relative;
  right: -1px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 16px;
  padding: 10px 20px;
  width: 57px;
  min-height: 212px;

  font-family: $ff-inter;
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;
  color: $color-tangaroa;
  border: none;

  background-color: $color-default-white;

  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  cursor: pointer;

  transform: translateZ(0);

  transition: color $trans-default, background-color $trans-default;

  -webkit-tap-highlight-color: transparent;

  &.is-not-click {
    pointer-events: none;
    cursor: pointer;
  }

  span {
    display: flex;
    transform: rotate(-90deg);

    transition: color $trans-default;
    transform: translateZ(0);

    &:last-of-type {
      display: none;
    }
  }

  @include vp-767 {
    position: fixed;
    top: 23px;
    right: 20px;
    z-index: 5;

    margin-top: 0;
    padding: 0;

    width: 36px;
    height: 36px;
    min-height: auto;

    background-color: transparentize($color-default-white, 0.12);

    border-radius: 50%;

    span {
      display: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 12px;

      width: 18px;
      height: 2px;

      background-color: $color-tangaroa;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 12px;

      width: 18px;
      height: 2px;

      background-color: $color-tangaroa;

      box-shadow: 0 -5px 0 $color-tangaroa;
    }
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover {
      span {
        color: transparentize($color-tangaroa, 0.2);
      }
    }

    &:active {
      span {
        color: transparentize($color-tangaroa, 0.5);
      }
    }
  }

  &.is-active {
    color: $color-default-white;

    background-color: $color-tangaroa;

    @include vp-767 {
      background-color: transparentize($color-default-white, 0.12);
      border-radius: 50%;

      &::before {
        top: 17px;
        transform: rotate(45deg);
      }

      &::after {
        bottom: 17px;
        transform: rotate(-45deg);
        box-shadow: 0 -5px 0 $color-transparent;
        box-shadow: none;
      }

      span {
        &:first-of-type {
          display: none;
        }

        &:last-of-type {
          display: none;
        }
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        span {
          color: transparentize($color-default-white, 0.2);
        }
      }

      &:active {
        span {
          color: transparentize($color-default-white, 0.5);
        }
      }
    }

    span {
      &:first-of-type {
        display: none;
      }

      &:last-of-type {
        display: flex;
      }
    }
  }
}
}

.main-nav-overlay  {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;

  opacity: 0;

  pointer-events: none;

  background-color: transparentize($color-default-white, 0.8);

  transition: opacity $trans-default;
  transition-delay: 0.1s;

  cursor: pointer;

  -webkit-tap-highlight-color: transparent;
}
