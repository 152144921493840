.card-route {
  &__header {
    max-width: 55%;
    margin-bottom: 45px;

    @include vp-767 {
      max-width: none;
      margin-bottom: 32px;
    }
  }

  &__title {
    margin-bottom: 16px;
    font-size: 30px;
    line-height: 1.2;
    font-family: $ff-neuemachina;

    @include vp-767 {
      margin-bottom: 14px;
      font-size: 20px;
    }
  }

  &__description {
    font-size: 14px;
    line-height: 1.45;

    @include vp-767 {
      font-size: 11px;
      line-height: 1.55;
    }
  }
}
