.slider-pagination {
  &__button {
    display: inline-block;
    margin-right: 7px;
    margin-left: 7px;
    width: 16px;
    height: 16px;

    background-color: $color-default-white;
    border-radius: 50%;

    opacity: 0.33;

    transform: scale(1);

    transition: transform $trans-default, opacity $trans-default;

    cursor: pointer;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }

    &.is-active {
      opacity: 1;
      transform: scale(0.5);
    }
  }
}
