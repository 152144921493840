.title {
  font-family: $ff-neuemachina;
  font-weight: 300;


  &--h1 {
    font-size: 116px;
    line-height: 110px;
    font-weight: 300;
    letter-spacing: -0.02em;

    @include vp-1023 {
      font-size: 100px;
    }

    @include vp-767 {
      font-size: 42px;
      line-height: 44px;
    }
  }

  &--h2 {
    font-size: 64px;
    line-height: 80px;

    @include vp-767 {
      font-size: 36px;
      line-height: 45px;
    }
  }

  &--nature {
    font-size: 52px;
    line-height: 65px;

    @include vp-1023 {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &--h3 {
    font-size: 30px;
    line-height: 38px;

    @include vp-767 {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &--h4 {
    font-size: 17px;
    font-weight: 800;

    @include vp-767 {
      font-size: 12px;
    }
  }

  &--inner {
    font-size: 96px;
    line-height: 102px;
    font-weight: 300;
    letter-spacing: -0.02em;

    @include vp-767 {
      font-size: 35px;
      line-height: 37px;
    }
  }
}
