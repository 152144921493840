.pagination {
  display: flex;
  align-items: center;
  gap: 11px;
  height: fit-content;

  @include vp-767 {
    gap: 4px;
  }

  span {
    width: 16px;
    height: 16px;

    background-color: $color-default-white;

    opacity: 0.33;

    @include vp-767 {
      width: 8px;
      height: 8px;
    }
  }

  span[aria-current="true"] {
    width: 8px;
    height: 8px;
    opacity: 1;

    @include vp-767 {
      width: 4px;
      height: 4px;
    }
  }
}
