.contacts {
  position: relative;
  min-height: 716px;

  @include vp-767 {
    min-height: 748px;
  }

  &__indent {
    padding-top: 64px;

    @include vp-767 {
      padding-top: 59px;
    }
  }

  &__img-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $color-default-white;
    border-radius: 8px;
    padding: 70px 64px 55px 64px;

    @include vp-767 {
      display: block;
      border: none;
      padding: 0;
    }
  }

  &__description {
    margin-top: -20px;
    margin-right: 30px;

    @include vp-767{
      margin-top: 0;
      margin-bottom: 35px;
      margin-right: 0;
    }
  }

  &__title-wrapper {
    margin-bottom: 17px;
  }

  &__description-title {
    font-family: $ff-neuemachina;
    font-weight: 300;
    font-size: 42px;
    line-height: 48px;
    color: $color-default-white;
    margin: 0;
  }

  &__arrow-icon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;

    @include vp-767 {
      margin-left: 13px;

      svg {
        width: 107px;
        height: 38px;
      }
    }
  }

  &__text {
    @include vp-767{
      display: none;
    }

    .paragraph {
      margin: 0;
      max-width: 427px;
    }
  }

  &__info {
    max-width: 531px;
    width: 100%;
    min-height: 460px;

    @include vp-767 {
      max-width: 100%;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    &:not(:last-of-type) {
      border-bottom: 1px solid $color-default-white;
      margin-bottom: 23px;
      padding: 0 0 24px;
    }

    @include vp-767 {
      &:not(:last-of-type) {
        border-bottom: 1px solid $color-default-white;
        margin-bottom: 20px;
        padding: 0 0 18px;
      }
    }
  }
}
