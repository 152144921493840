.music-block {
  // display: flex;

  iframe {
    width: 100%;
    border: none;

    @include vp-767 {
      height: 71px;
    }
  }
}
