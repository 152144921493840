// Vendor
// ---------------------------------

@import "vendor/normalize";
// Swiper 7.4.1
@import "vendor/swiper";

// Global
// ---------------------------------

@import "variables";
@import "mixins";
@import "functions";
@import "global/fonts";
@import "global/reboot";
@import "global/utils";
@import "global/container";

// Blocks
// ---------------------------------

@import "blocks/accordion/accordion";
@import "blocks/accordion/data-accordion";
@import "blocks/tabs/tabs";
@import "blocks/tabs/data-tabs";

@import "blocks/header";
@import "blocks/main-nav";
@import "blocks/main-screen";
@import "blocks/nature";
@import "blocks/contacts";
@import "blocks/tour";
@import "blocks/footer";
@import "blocks/description-list";
@import "blocks/modal";
@import "blocks/btn";
@import "blocks/custom-input";
@import "blocks/custom-toggle";
@import "blocks/custom-select";
@import "blocks/title.scss";
@import "blocks/paragraph";
@import "blocks/roads";
@import "blocks/card-info";
@import "blocks/pagination";
@import "blocks/link";
@import "blocks/location-nav";
@import "blocks/location-map";
@import "blocks/location-block";
@import "blocks/relocation-block";
@import "blocks/music-block";
@import "blocks/slider-pagination";
@import "blocks/header-links";
@import "blocks/relocation-parameters";
@import "blocks/transport-block";
@import "blocks/card-transport";
@import "blocks/tabs-control";
@import "blocks/car-list";
@import "blocks/people";
@import "blocks/video-slide";
@import "blocks/contacts-item-card";
@import "blocks/scroll-to-top";
@import "blocks/route-block";
@import "blocks/card-route";
@import "blocks/route-list";

// ---------------------------------

// ❗❗❗ запрещено использовать изображения в css, касается как jpg \ png, так и svg

// ❗ обязательно используйте @media для отключения ховеров на тач устройствах
// но не используем для текстовых полей ( input, textarea )
/* @media (hover: hover), screen and (min-width: 0\0) {
  &:hover,
  &:focus {
    color: $color-default-white;
  }
} */

// адаптив пишем внутри каждого класса (смотрим container.scss)

// для фикса проблем с vh на iOS в сборке подключен скрипт
// используя vh на проекте задавайте их также как в примере в utils.scss

// для любых transition обязательно указывайте transition-property
// transition: $trans-default ❌ ---> transition: color $trans-default ✅
