.scroll-to-top {
  a  {
    position: fixed;
    right: 35px;
    bottom: 49.3px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 35.7px;
    background: rgba(255, 255, 255, 0.12);
    border-radius: 33px;
  }

  @include vp-767 {
    display: none;
  }
}
