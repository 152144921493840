.relocation-parameters {
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    color: $color-tangaroa;

    svg {
      margin-right: 18px;
      flex-shrink: 0;
      fill: $color-mustard;

      padding: 7px;
      background-color: $color-mustard;
      border-radius: 50%;

      @include vp-767 {
        margin-right: 12px;

        padding: 4px;
        width: 24px;
        height: 24px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__text {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 28px;

    color: $color-default-white;

    @include vp-767 {
      font-size: 18px;
      line-height: 28px;
    }
  }
}

